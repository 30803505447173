import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const RefSource = ({ refKey, request_id, request_index, source_doc, onClick }) => {
    let pages_str = ""
    // console.log(source_doc);
    try {
        if (source_doc.fr_coords == null) {
            pages_str = null;
        } else {
            let start_page = source_doc.fr_coords.start.page;
            let end_page = source_doc.fr_coords.end.page;
            if (start_page == end_page) {
                pages_str = start_page;
            } else {
                pages_str = `${start_page}-${end_page}`;
            }
        }
    }
    catch {
        pages_str = "ошибка";
    }
    return (
        <Card
            sx={{
                minHeight: '50px',
                borderRadius: '8px',  // Reduced border radius
                boxShadow: 2,  // Slightly reduced shadow
                border: 'none',  // Ensure no border is set
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                    transform: 'scale(1.02)',
                    boxShadow: 4,  // Reduced shadow on hover
                },
                marginBottom: '0px',  // Ensure no margin at the bottom
                padding: 0,  // Remove any padding on the Card itself
            }}
            raised={true}
            onClick={() => onClick(refKey)}
        >
            <CardContent >  {/* Further reduced padding inside the card */}
                <Typography
                    variant="body2"
                    sx={{ lineHeight: 1, margin: 0 }}  // Reduced line height and removed margins
                >
                    [{refKey}] : {source_doc.title}{pages_str ? `, стр ${pages_str}` : ''}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default RefSource;
